<template>
	<div class="account-main wrap fColor1" style="padding-left: 20px;padding-top: 80px;">
		<p>{{$t('bank.BindingCard')}}</p>
		<div class="main-input">
			<div class="">
				<p class="list">{{$t('auth.name')}}：</p>
				<input type="text" style="width:98%;height: 40px;" :placeholder="$t('bank.PayeeName')" id="name" maxlength="50"
					v-model="receive_name" class="bg-part bd input_color">
			</div>
			<!-- <div class="">
				<p class="list">銀行名称：</p>
				<input type="text" style="width:98%;height: 40px;" placeholder="銀行名称" id="card" v-model="bank_name"
					class="bg-part bd input_color">
			</div>
			<div class="">
				<p class="list">銀行编码：</p>
				<input type="text" style="width:98%;height: 40px;" placeholder="銀行编码" id="card" v-model="bank_code"
					class="bg-part bd input_color">
			</div> -->
			<div class="">
				<p class="list">{{$t('bank.bankName')}}：</p>
				<select v-model="selected"
					style="width: 50%;background-color:#1a243b;border: 1px solid #2e3753;color: #c7cce6;height: 40px;">
					<option v-for="option in bankbiao" v-bind:value="option.code" style="">
						<p>{{option.name}}</p>

					</option>
				</select>
				<span v-model="bank_code">{{ selected }}</span>
			</div>

			<div class="">
				<p class="list">{{$t('bank.PayeeAdd')}}:</p>
				<input type="text" style="width:98%;height: 40px;" :placeholder="$t('bank.fee')" maxlength="50" id="card"
					v-model="receive_account" class="bg-part bd input_color">
			</div>

		</div>
		<div class="tijiao" @click="Submit()">
			{{$t('bank.submit')}}
		</div>

	</div>
</template>
<script>
	export default {
		name: "bandbank",
		data() {
			return {
				// 收款人姓名
				receive_name: "",
				// 銀行名称
				bank_name: '',
				// 銀行编码:
				bank_code: '',
				// 收款地址
				receive_account: '',
				selected: 'BCA',
				bankbiao: [],

			};
		},
		created() {
			this.bandlisthome()
			this.bandlist()
			this.token = localStorage.getItem("token");
		},
		mounted() {

			// this.Info();
		},
		methods: {
			bandlist() {
				this.$ajax({
					url: "/api/user/get_bank_list",
					method: "post",
					data: '',
					headers: {
						'Authorization': localStorage.getItem('token')
					},
				}).then(res => {
					if (res.data.type == "ok") {
						console.log(res.data.message)
						this.bankbiao = res.data.message
					} else {
						layer.msg(res.data.message);
					}
				});
			},
			bandlisthome() {
				this.$ajax({
					url: "/api/user/get_user_bank",
					method: "post",
					data: '',
					headers: {
						'Authorization': localStorage.getItem('token')
					},
				}).then(res => {
					if (res.data.type == "ok") {
						console.log(res.data.message)
						this.homeshow = res.data.message
						this.receive_name = res.data.message.receive_name
						this.receive_account = res.data.message.receive_account
						this.bank_code = res.data.message.bank_name
						this.selected = res.data.message.bank_code
						console.log(this.selected)


					} else {
						layer.msg(res.data.message);
					}
				});
			},
			Submit() {
				if (this.receive_name == "") {
					layer.msg("收款人姓名");
					return;
				}
				// if (this.bank_name.length == "") {
				// 	layer.msg("銀行名称");
				// 	return;
				// }
				// if (this.bank_code.length == "") {
				// 	layer.msg("銀行编码");
				// 	return;
				// }

				if (this.receive_account == "") {
					layer.msg("收款");
					return;
				}

				let bankName
				this.bankbiao.forEach(el => {
					if (this.selected == el.code) {
						bankName = el.name
					}
				})

				let data = {
					// 收款人姓名
					receive_name: this.receive_name,
					// 銀行名称
					bank_name: bankName,
					// 銀行编码:
					bank_code: this.selected,
					// 收款地址
					receive_account: this.receive_account

				};

				console.log(data)
				// return
				this.$ajax({
					url: "/api/user/add_bank",
					method: "post",
					data: data,
					headers: {
						'Authorization': localStorage.getItem('token')
					},
				}).then(res => {
					if (res.data.type == "ok") {
						layer.msg(res.data.message);
						// setTimeout(function(){
						//     location.reload();
						// },1500)
					} else {
						// layer.msg(res.data.message);
					}
				});
			}

		},

	};
</script>
<style scoped lang="scss">
	.list {
		margin-top: 30px;
		margin-bottom: 15px;
	}

	.text {
		width: 90%;
		margin-top: 10px;
	}

	.tijiao {

		margin-top: 50px;
		width: 160px;
		height: 45px;
		background: #5697F4;
		border-radius: 2px 2px 2px 2px;
		opacity: 1;
		line-height: 45px;
		text-align: center;
		color: white;
	}
</style>
